import React from "react";
import "./bannerPublicitario.css";
// Imports
import bodega1 from "../../assets/banners/1000/Bodega_1_1000.png";
import bodega2 from "../../assets/banners/1000/Bodega_2_1000.png";
import bodegaFamiliar from "../../assets/banners/1000/Bodega_3_1000.png";
import cabanaElDiamante from "../../assets/banners/1000/Cabaña El Diamante_1000.png";
import elArtesano from "../../assets/banners/1000/Churrería El Artesano_1000.png";
import cinepolis from "../../assets/banners/1000/Cinepolis_1000.png";
import dulceMorena from "../../assets/banners/1000/Dulce Morena_1000.png";
import eduardoCoiffeur from "../../assets/banners/1000/Eduardo Coiffeur_1000.png";
import elNinoFeliz from "../../assets/banners/1000/El niño feliz_1000.png";
import jmBikes from "../../assets/banners/1000/JM Bikes_1000.png";
import seitu from "../../assets/banners/1000/Seitu_1000.jpg";
import grantino from "../../assets/banners/1000/Grantino_1000.jpg";
import tamarisco from "../../assets/banners/1000/Tamarisco_1000.jpg";
import tina from "../../assets/banners/1000/Tina_1000.jpg";
import jmBikes2 from "../../assets/banners/1000/JM Bikes2_1000.jpg";
import martinRepuestos from "../../assets/banners/1000/Martin Respuestos_1000.png";
import miguitaDePan from "../../assets/banners/1000/Miguita de pan_1000.png";
import mood from "../../assets/banners/1000/Mood_1000.png";
import mueblesDiaz from "../../assets/banners/1000/Muebles Días_1000.png";
import opticaSenso from "../../assets/banners/1000/Optica Senso_1000.png";
import parrillaRanchoGrande from "../../assets/banners/1000/Parrilla Rancho Grande_1000.png";
import sushiMak from "../../assets/banners/1000/sushi mak_1000.png";
import fedorco from "../../assets/banners/1000/Fedorco_1000.png";
import laYunta from "../../assets/banners/1000/La Yunta_1000.jpg";
import mkMotoPartes from "../../assets/banners/1000/mk_moto_partes_1000.jpg";
import accesoriosElNacho from "../../assets/banners/1000/El_nacho_1000.jpg";
import mercadoDeCarnes from "../../assets/banners/1000/Mercado De Carnes_1000.jpg";

export const imagesMapping = {
  1: [
    { image: bodegaFamiliar, url: "https://www.familiaschroeder.com/" },
    { image: fedorco, url: "https://www.fedorco.com/" },
    { image: opticaSenso, url: "https://www.opticasenso.com/" },
  ],
  2: [
    {
      image: parrillaRanchoGrande,
      url: "https://www.instagram.com/ranchograndenqn/",
    },
    {
      image: miguitaDePan,
      url: "https://www.instagram.com/miguitasdepan/?hl=es",
    },
    { image: cinepolis, url: "https://www.cinepolis.com.ar/" },
  ],
  3: [
    {
      image: tina,
      url: "https://www.instagram.com/tina.mayorista/",
    },
    { image: mueblesDiaz, url: "https://www.facebook.com/MUEBLESDIAZNEUQUEN/" },
    { image: laYunta, url: "https://www.instagram.com/layuntaresto/" },
    { image: jmBikes, url: "https://www.instagram.com/jmbikesnqn/" },
  ],
  4: [
    {
      image: tamarisco,
      url: "https://www.instagram.com/tamarisco.nqn/",
    },
    {
      image: seitu,
      url: "https://www.instagram.com/seitu_nqn/",
    },
    {
      image: grantino,
      url: "https://www.instagram.com/grantino.oficiall/",
    },
  ],
  5: [
    { image: dulceMorena, url: "https://www.instagram.com/dulcemorenanqn/" },
    { image: mkMotoPartes, url: "https://www.instagram.com/mkmotopartes/" },
    {
      image: mercadoDeCarnes,
      url: "https://www.instagram.com/mercadodecarnes.nqn/",
    },
    { image: sushiMak, url: "https://www.sushimak.com.ar/" },
  ],
};

const BannerPublicitario = ({ adIndex }) => {
  const dayIndex = ((new Date().getDay() - 1) % 5) + 1;
  const adsForToday = imagesMapping[dayIndex] || [];
  if (
    !adsForToday.length ||
    typeof adIndex === "undefined" ||
    adIndex > adsForToday.length - 1
  ) {
    return null;
  }

  const ad = adsForToday[adIndex];

  return (
    <a href={ad.url} target="_blank" rel="noopener noreferrer">
      <img src={ad.image} className="ad-image" alt="AD" />
    </a>
  );
};

export default BannerPublicitario;
